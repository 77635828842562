import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Button, Alert, Tab, Nav, NavItem } from "react-bootstrap";
import { reduxForm, Field, Form } from "redux-form";
import Container from "@common/components/Container/Container";
import CustomField from "@beers/pages/CreateRecipe/CustomField/CustomField";
import FileInput from "@common/components/FileInput/FileInput";
import Divider from "@common/components/Divider/Divider";
import ButtonWithLoad from "@common/components/ButtonWithLoad/ButtonWithLoad";
import ChangePassword from "../ChangePassword/ChangePassword";
import PrivacyPolicyContent from "@auth/pages/PrivacyPolicy/PrivacyPolicyContent";
import TcContent from "@auth/pages/TermsAndConditions/TcContent";
import { getAccessTypeName } from "@common/constants/accessTypes";
import "./Account.scss";
import {
  cancelSubscription,
  patchUserInformation
} from "@profile/actions/userActions";

class Account extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displaySuccessMessage: false,
      initialEmail: this.props.initialValues.email,
      edit: false,
      startCancel: false,
      cancelSuccess: false
    };
  }
  componentDidUpdate(prevProps) {
    // If we just finished a request without error AKA
    if (
      // These 3 first conditions mean "after a successful API request"
      prevProps.isFetching &&
      !this.props.isFetching &&
      !this.props.error &&
      // And not on the first GET request
      prevProps.initialValues.email !== ""
    ) {
      this.toggleSuccessMessage();
    }
  }
  setEdit = () => {
    this.setState({ edit: !this.state.edit });
  };
  toggleSuccessMessage = type => {
    if (type === "cancel") {
      this.setState({
        cancelSuccess: true
      });
      setTimeout(() => {
        this.setState({ cancelSuccess: false });
      }, 3000);
    } else {
      this.setState({ displaySuccessMessage: true });
      setTimeout(() => {
        this.setState({ displaySuccessMessage: false });
      }, 3000);
    }
  };

  cancel = subscription => {
    this.props.cancelSubscription(subscription.subscription_id);
  };

  submit = data => {
    const { props, state } = this;
    // We remove 'email' from data to only send it if needed
    const { email, ...rest } = data;
    const shouldUpdateEmail = email !== state.initialEmail;
    props.patchUserInformation(
      {
        ...rest,
        email: shouldUpdateEmail ? email : undefined,
        image: this.imageField ? this.imageField.files[0] : null
      },
      () => {
        this.toggleSuccessMessage();
        shouldUpdateEmail && this.setState({ initialEmail: email });
      }
    );
    this.setEdit();
  };

  render() {
    const {
      errorMessage,
      handleSubmit,
      isPatching,
      isCancelling,
      userImage,
      initialValues,
      access_type,
      subscription_account_token,
      subscription_account_id,
      subscription
    } = this.props;
    let dateOptions = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric"
    };
    const defaultButtons = (
      <div>
        <Button onClick={this.setEdit}>Edit</Button>
      </div>
    );
    const editingButtons = (
      <div>
        <Button onClick={this.setEdit}>Cancel</Button>
        <ButtonWithLoad type="submit" bsStyle="primary" isLoading={isPatching}>
          Save
        </ButtonWithLoad>
      </div>
    );
    const fields = [
      // [0] -> label, [1] -> input_name
      ["Email", "email"],
      ["Username", "display_name"],
      ["First name", "first_name"],
      ["Last name", "last_name"],
      ["Bio", "bio"]
    ];
    return (
      <Form className="account" onSubmit={handleSubmit(this.submit)}>
        <Tab.Container id="account-tabs" defaultActiveKey="1">
          <Row className="clearfix">
            <Col sm={4}>
              <Container style={{ marginTop: "80px" }}>
                <Nav bsStyle="pills" stacked>
                  <NavItem eventKey="1">Profile</NavItem>
                  <NavItem eventKey="3">Membership</NavItem>
                  <NavItem eventKey="2">Change Password</NavItem>
                  <div style={{ margin: "10px 0px" }}>
                    <Divider />
                  </div>
                  <NavItem eventKey="6">Terms & Conditions</NavItem>
                  <NavItem eventKey="7">Privacy Policy</NavItem>
                </Nav>
              </Container>
            </Col>
            <Col sm={8}>
              <Tab.Content animation>
                <Tab.Pane eventKey="1">
                  {" "}
                  <Col sm={12}>
                    <Row className="account__data">
                      <Col sm={12} style={{ textAlign: "center" }}>
                        <img src={userImage} className="brewery-image" />
                        {this.state.edit && (
                          <FileInput
                            name="image"
                            refProp={r => (this.imageField = r)}
                          />
                        )}
                      </Col>
                      <Col sm={12} style={{ textAlign: "center" }}>
                        <h2 style={{ marginTop: "5px" }}>
                          {initialValues.first_name} {initialValues.last_name}
                        </h2>
                        <Divider />
                      </Col>

                      <Col sm={12}>
                        {fields.map(([label, formName]) => (
                          <div key={formName}>
                            <Col sm={12} md={8} mdOffset={2}>
                              <Field
                                label={label}
                                name={formName}
                                component={CustomField}
                                smSize={12}
                                disabled={!this.state.edit}
                              />
                            </Col>
                          </div>
                        ))}
                        {isPatching && (
                          <Col sm={12}>
                            <span className="ion-load-c spin">
                              {" "}
                              Updating profile information...
                            </span>
                          </Col>
                        )}
                        {errorMessage && (
                          <Col sm={12}>
                            <Alert bsStyle="danger">
                              <span className="ion-alert-circled">
                                {errorMessage}
                              </span>
                            </Alert>
                          </Col>
                        )}
                        {this.state.displaySuccessMessage && (
                          <Col sm={12}>
                            <Alert bsStyle="success">
                              <span className="checkmark-circle">
                                Information updated correctly.
                              </span>
                            </Alert>
                          </Col>
                        )}
                      </Col>
                      <Col sm={12} md={8} mdOffset={2}>
                        <div className="account__buttons">
                          {this.state.edit ? editingButtons : defaultButtons}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Tab.Pane>
                <Tab.Pane eventKey="2">
                  <div style={{ paddingTop: "20px", paddingRight: "20px" }}>
                    <ChangePassword />
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="6">
                  <div style={{ paddingTop: "20px" }}>
                    <Container>
                      <TcContent />
                    </Container>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="3">
                  <Row className="account__data">
                    <Col sm={12} style={{ textAlign: "center" }}>
                      <img src={userImage} className="brewery-image" />
                    </Col>
                    <Col sm={12} style={{ textAlign: "center" }}>
                      <h2 style={{ marginTop: "5px" }}>
                        {initialValues.first_name} {initialValues.last_name}
                      </h2>
                      <Divider />
                    </Col>
                    {(this.state.startCancel && (
                      <Col sm={12}>
                        <Col sm={12} md={8} mdOffset={2}>
                          <h2
                            style={{
                              fontFamily: "Gotham-Medium",
                              width: "50%"
                            }}
                          >
                            Have you finished Brewing? If you're sure you'd like
                            to cancel your membership, finish below.
                          </h2>
                          <Row>
                            <ul
                              style={{
                                fontFamily: "Gotham-Light",
                                fontSize: "13px"
                              }}
                            >
                              <li>
                                You will no longer have access to the Pro
                                Membership starting{" "}
                                {new Date(
                                  (subscription.next_payment_date &&
                                    subscription.next_payment_date) ||
                                    subscription.end_date
                                ).toLocaleString("en-US", dateOptions)}
                                .
                              </li>
                              <li>
                                Come back at any time. If you restart within 2
                                months your recipes and account details will
                                still be waiting for you.
                              </li>
                            </ul>
                          </Row>
                          <ButtonWithLoad
                            onClick={() =>
                              this.setState({
                                startCancel: false
                              })
                            }
                          >
                            Go Back
                          </ButtonWithLoad>
                          <ButtonWithLoad
                            bsStyle="primary"
                            onClick={() => this.cancel(subscription)}
                            disabled={
                              this.props.cancelResponse ===
                              subscription.subscription_id
                            }
                          >
                            Confirm Cancellation
                          </ButtonWithLoad>
                          {isCancelling && (
                            <Col sm={12}>
                              <span className="ion-load-c spin">
                                {" "}
                                Cancelling your subscription...
                              </span>
                            </Col>
                          )}
                          {this.props.cancelResponse ===
                            subscription.subscription_id && (
                            <Col sm={12}>
                              <Alert bsStyle="success">
                                <span className="checkmark-circle">
                                  Membership cancelled successfully.
                                </span>
                              </Alert>
                            </Col>
                          )}
                          {errorMessage && (
                            <Col sm={12}>
                              <Alert bsStyle="danger">
                                <span className="checkmark-circle">
                                  {errorMessage}
                                </span>
                              </Alert>
                            </Col>
                          )}
                        </Col>
                      </Col>
                    )) ||
                      (subscription_account_id &&
                        subscription_account_id.includes("cst_") && (
                          <Col sm={12}>
                            <Col sm={12} md={8} mdOffset={2}>
                              <h2 style={{ fontFamily: "Gotham-Medium" }}>
                                Current Membership Information
                              </h2>
                              <Row className="member__banner">
                                <span className="ion-ribbon-b" style={{ color: 'black', padding: '10px', fontSize: '36px' }}></span>
                                Type: {(getAccessTypeName(access_type))}
                              </Row>
                              <Row className="member__banner">
                                <span className="ion-clock" style={{ color: 'black', padding: '10px', fontSize: '36px' }}></span>
                                Frecuency: {(subscription === undefined && "Lifetime") ||
                                  (subscription.interval === "1 month" && "Monthly") ||
                                  (subscription.interval === "12 months" && "Annual")}
                              </Row>
                              <Row>
                                {(subscription !== undefined &&
                                  subscription.status === "active" &&
                                  this.props.cancelResponse !==
                                    subscription.subscription_id && (
                                    <ul style={{ fontSize: "13px" }}>
                                      <li>
                                        You've been a Pro Member since{" "}
                                        {new Date(
                                          subscription.created
                                        ).toLocaleString("en-US", dateOptions)}
                                      </li>
                                      <li>
                                        The next payment will be on{" "}
                                        {new Date(
                                          subscription.next_payment_date
                                        ).toLocaleString("en-US", dateOptions)}
                                      </li>
                                    </ul>
                                  )) ||
                                  (subscription !== undefined && (
                                    <ul style={{ fontSize: "13px" }}>
                                      <li>
                                        Your Pro Membership access ends on{" "}
                                        {new Date(
                                          (subscription.next_payment_date &&
                                            subscription.next_payment_date) ||
                                            subscription.end_date
                                        ).toLocaleString("en-US", dateOptions)}
                                      </li>
                                    </ul>
                                  ))}
                              </Row>
                              {subscription !== undefined &&
                                subscription.status === "active" && (
                                  <ButtonWithLoad
                                    onClick={() =>
                                      this.setState({
                                        startCancel: true
                                      })
                                    }
                                    style={{ float: "right" }}
                                    disabled={
                                      this.props.cancelResponse ===
                                      subscription.subscription_id
                                    }
                                  >
                                    Cancel Membership
                                  </ButtonWithLoad>
                                )}
                            </Col>
                          </Col>
                        )) ||
                      ((subscription_account_id !== null && (
                        <Col sm={12}>
                          <Col sm={12} md={8} mdOffset={2}>
                            <h2>Current Membership Information</h2>
                            <Row className="member__banner">
                              Annual Pro Membership Coupon
                            </Row>
                            <Row>
                              <ul>
                                <li>
                                  <NavItem
                                    href={
                                      "https://minibrew.recurly.com/account/billing_info/edit?ht=" +
                                      subscription_account_token
                                    }
                                    target="_blank"
                                  >
                                    Billing Details <i className="ion-link" />
                                  </NavItem>
                                </li>
                                <li>
                                  <NavItem
                                    href={
                                      "https://minibrew.recurly.com/account/" +
                                      subscription_account_token
                                    }
                                    target="_blank"
                                  >
                                    Manage Subscriptions{" "}
                                    <i className="ion-link" />
                                  </NavItem>
                                </li>
                              </ul>
                            </Row>
                          </Col>
                        </Col>
                      )) || (
                        <Col sm={12}>
                          <Col sm={12} md={8} mdOffset={2}>
                            <h2>Current Membership Information</h2>
                            <Row className="member__banner">
                              Lifetime Pro Membership
                            </Row>
                            <Row />
                          </Col>
                        </Col>
                      ))}
                  </Row>
                </Tab.Pane>

                <Tab.Pane eventKey="7">
                  <div style={{ paddingTop: "20px" }}>
                    <Container>
                      <PrivacyPolicyContent />
                    </Container>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Form>
    );
  }
}

const mapStateToProps = ({ userInfo }) => {
  const {
    errorMessage,
    isFetching,
    isPatching,
    subscription,
    isCancelling,
    cancelResponse
  } = userInfo;
  const {
    email,
    bio,
    first_name,
    last_name,
    display_name,
    image,
    access_type,
    subscription_account_token,
    subscription_account_id
  } = userInfo.user;
  return {
    userImage: image,
    errorMessage,
    isFetching,
    isPatching,
    isCancelling,
    cancelResponse,
    access_type,
    subscription_account_token,
    subscription_account_id,
    subscription,
    initialValues: {
      email,
      bio,
      first_name,
      last_name,
      display_name
    }
  };
};

const AccountForm = reduxForm({
  form: "edit-account",
  enableReinitialize: true // Enables getting async initial values
})(Account);

export default connect(
  mapStateToProps,
  {
    cancelSubscription: subscription_id => cancelSubscription(subscription_id),
    patchUserInformation
  }
)(AccountForm);
