export const ACCESS_TYPES = {
  BP_STARTER: 0,
  BP_PRO: 1,
  BP_PARTNER: 2,
  BP_RESEARCHER: 3,
  BP_LEGEND: 4,
  BP_AMBASSADOR: 5,
};

export const ALLOWED_TO_ACCESS = [
  ACCESS_TYPES.BP_PRO,
  ACCESS_TYPES.BP_PARTNER,
  ACCESS_TYPES.BP_RESEARCHER,
  ACCESS_TYPES.BP_LEGEND,
  ACCESS_TYPES.BP_AMBASSADOR,
];

export function getAccessTypeName(accessType) {
  switch (accessType) {
    case ACCESS_TYPES.BP_STARTER:
      return "Starter";
    case ACCESS_TYPES.BP_PRO:
      return "Pro";
    case ACCESS_TYPES.BP_PARTNER:
      return "Partner";
    case ACCESS_TYPES.BP_RESEARCHER:
      return "Researcher";
    case ACCESS_TYPES.BP_LEGEND:
      return "Legend";
    case ACCESS_TYPES.BP_AMBASSADOR:
      return "Ambassador";
    default:
      return "Unknown"; // Handle cases where the accessType is not recognized
  }
}
