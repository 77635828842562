import { post } from "@common/services/request";
import { apiEndpoints } from "@common/constants/urls";

export function authenticateUser(credentials, headers) {
  const body = {
    email: credentials.email,
    password: credentials.password
  };

  return post(apiEndpoints.token, body, headers);
}

export const registerUser = (body, headers) => {
  const fd = new FormData();
  fd.append("json_data_part", JSON.stringify(body));

  return post("v1/users/", fd, headers);
};

export const activateCoupon = coupon => {
  return post(apiEndpoints.activateCoupon, { coupon });
};

export const setSubscription = interval => {
  const body = {
    interval: interval
  };
  return post("v1/memberships/create/", body);
};

/* This is for the new subscription strategy, Not in use yet */
export const setSubscriptionType = (type, interval) => {
  const body = {
    type: type,
    interval: interval
  };
  return post("v1/memberships/create/", body);
};
