import React, { useState, useEffect } from "react";
import { Alert, Grid } from "react-bootstrap";
import { routes } from "@common/constants/urls";
import ButtonWithLoad from "@common/components/ButtonWithLoad/ButtonWithLoad";
import { SubSelector } from "./SubSelector";
import { setSubscription } from "@auth/services/authenticationService";
import Stepper from "react-stepper-horizontal";
import { NavLink } from "react-router-dom";
import "./activate-subscription.scss";
import { SubOverview } from "./SubOverview";
import Navigation from "@common/components/Navigation/Navigation";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as userActions from "@profile/actions/userActions";

const ActivateSubscription = ({ userInfo, getUserInformation }) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(false);
  const [selectedYear, setSelectedYear] = useState(true);
  const [selectedSub, setSelectedSub] = useState("Year");

  const activate = () => {
    if (userInfo.user === null) {
      setError(
        <NavLink to={routes.signin}>
          Something went wrong. Click here to return to sign in.
        </NavLink>
      );
      return;
    }

    setError(null);
    setLoading(true);
    setSubscription(selectedSub)
      .then(response => {
        window.location.href = response.checkout_href;
      })
      .catch(error => setError(error));
  };
  useEffect(() => {
    getUserInformation();
  }, []);

  const handleSubSelect = interval => {
    switch (interval) {
      case "Month":
        setSelectedSub(interval);
        setSelectedYear(false);
        setSelectedMonth(true);
        setSelectedSub("Month");
        break;
      case "Year":
        setSelectedSub(interval);
        setSelectedMonth(false);
        setSelectedYear(true);
        setSelectedSub("Year");
        break;
    }
  };
  const checkmarkStyle = { paddingRight: 10, color: "#fdcc00" };
  return (
    <div className="wrapper--auth ">
      <meta
        name="viewport"
        content="user-scalable=no, initial-scale=1.0, maximum-scale=1.0"
      />
      <div className="subscribe-header">
        <Navigation
          showBurger={false}
          auth={true}
          color="black"
          colorLogo={true}
          portal={false}
        >
          <div className="col-sm-5" />
          <div
            className="col-sm-3 coupon"
            style={{ padding: "20px", textAlign: "end" }}
          >
            <NavLink
              activeClassName="active"
              className="coupon-link"
              to={routes.coupon}
              style={{ color: "#000" }}
            >
              ACTIVATE COUPON
            </NavLink>
          </div>
        </Navigation>
      </div>
      <div className="white-bg">
        <div className="stepper-container">
          <Stepper
            steps={[
              {
                title: "Register"
              },
              { title: "Select Membership" },
              { title: "Payment" }
            ]}
            activeStep={1}
            activeColor={"rgb(253, 204, 0)"}
            defaultColor="rgba(253, 204, 0, 0.44)"
            completeColor="rgb(253, 204, 0)"
            defaultBorderColor="#000"
            size={40}
            completeBarColor="rgb(253, 204, 0)"
            circleFontColor="rgb(117, 117, 117)"
          />
        </div>
        <Grid fluid style={{ backgroundColor: "ghostwhite" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center"
            }}
          >
            <div
              className="selection-container"
              style={{
                display: "flex",
                flexDirection: "column"
              }}
            >
              <div>
                <h1 className="register__title">Select your Membership.</h1>
                <h3 className="register__subtitle" style={{ marginBottom: 0 }}>
                  <span
                    className="ion-checkmark-round"
                    style={checkmarkStyle}
                  />
                  Brew any recipe you want
                </h3>
                <h3 className="register__subtitle">
                  <span
                    className="ion-checkmark-round"
                    style={checkmarkStyle}
                  />
                  Share your recipes with the community
                </h3>
              </div>
              <div className="selector-row">
                <SubSelector
                  onClick={() => handleSubSelect("Month")}
                  selected={selectedMonth}
                >
                  Monthly {"\n"} Pro
                </SubSelector>
                <SubSelector
                  onClick={() => handleSubSelect("Year")}
                  selected={selectedYear}
                  style={{ marginLeft: "30px" }}
                >
                  Annual {"\n"} Pro
                </SubSelector>
              </div>
            </div>
          </div>
          <SubOverview
            selectedYear={selectedYear}
            selectedMonth={selectedMonth}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%"
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignSelf: "center"
              }}
            >
              <ButtonWithLoad
                isLoading={loading}
                bsStyle="primary"
                onClick={activate}
                disabled={!selectedSub}
                className="activate__form__button"
              >
                Next
              </ButtonWithLoad>
              <small
                style={{
                  fontStyle: "italic",
                  fontSize: "105%",
                  padding: "10px",
                  textAlign: "center"
                }}
              >
                You will be charged € 14.95 to confirm payment and setup subscription
              </small>
            </div>
          </div>
          {error && (
            <Alert
              bsStyle="danger"
              style={{ marginTop: "10px", textAlign: "center" }}
              onDismiss={() => setError(null)}
            >
              {error}
            </Alert>
          )}
        </Grid>
      </div>
    </div>
  );
};
function mapStateToProps({ userInfo }) {
  return { userInfo };
}

ActivateSubscription.propTypes = {
  userInfo: PropTypes.object
};
function mapDispatchToProps(dispatch) {
  return {
    getUserInformation: token => dispatch(userActions.getUserInformation(token))
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivateSubscription);
